import React from "react"
import { Carousel } from "./Carousel"

function Hero(props) {
  const { carouselImages, carouselHeadline, carouselPageLink, carouselPageLinkDescription } = props
  return (
    <section className="hero">
      <Carousel carouselImages={carouselImages} carouselPageLink={carouselPageLink} carouselPageLinkDescription={carouselPageLinkDescription} />
      <h2 className="hero__subheadline">{carouselHeadline}</h2>
    </section>
  )
}

export { Hero }
