import React, { useEffect, useRef } from "react"
import { Link } from "gatsby"
import Glide from "@glidejs/glide"

function Carousel(props) {
  const { carouselImages, carouselPageLink, carouselPageLinkDescription } = props
  const carouselEntryPoint = useRef(null)

  useEffect(() => {
    new Glide(carouselEntryPoint.current, { autoplay: 4000 }).mount()
  }, [])

  // Will return as many buttons as necessary, based on how many images the user uploads.
  function generateCarouselButtons() {
    return carouselImages.map((image, idx) => (
      <button key={image.home_carousel_image.localFile.childImageSharp.fluid.src} className="glide__bullet" data-glide-dir={`=${idx}`} type="button" />
    ))
  }

  // Will move the "View Work" text as far left as necessary, based on how many images the user uploads.
  function calculateLinkTranslatePosition() {
    const spacingObj = {
      dotSpacing: `${-(12 * carouselImages.length)}px`,
      imageSpacing: `${2 + carouselImages.length}rem`,
    }
    return spacingObj
  }

  return (
    <div className="glide" ref={carouselEntryPoint}>
      <div data-glide-el="track" className="glide__track">
        <ul className="glide__slides">
          {carouselImages.map(image => (
            <li key={image.home_carousel_image.localFile.childImageSharp.fluid.src} className="glide__slide">
              <img src={image.home_carousel_image.localFile.childImageSharp.fluid.src} alt="Robert Idol Showcase" />
            </li>
          ))}
        </ul>
      </div>
      <div className="carousel__container">
        <Link
          style={{ transform: `translateX(calc(${calculateLinkTranslatePosition().dotSpacing} - ${calculateLinkTranslatePosition().imageSpacing})` }}
          className="carousel__link"
          to={`/${carouselPageLink.match(/([^/]*)\/*$/)[1]}`}>
          {carouselPageLinkDescription}
        </Link>
      </div>
      <div className="glide__bullets" data-glide-el="controls[nav]">
        {generateCarouselButtons()}
      </div>
    </div>
  )
}

export { Carousel }
