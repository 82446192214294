import React from "react"
import { graphql } from "gatsby"

// Utility Functions
import { handleMenuItems } from "../utils/handleMenuItems"

// Custom components
import { Nav } from "../components/Nav"
import { Hero } from "../components/Hero"
import { Banners } from "../components/Banners"
import { Footer } from "../components/Footer"

// Home page
export default function IndexPage(props) {
  const {
    data: {
      wordpressPage: {
        acf: {
          home_carousel: carousel,
          home_carousel_headline: carouselHeadline,
          home_carousel_page_link: carouselPageLink,
          home_carousel_page_link_description: carouselPageLinkDescription,
          home_banner: banner,
        },
      },
      wordpressAcfOptions: {
        options: {
          sitewide_company_information_company_name: companyName,
          sitewide_company_information_company_twitter: companyTwitter,
          sitewide_company_information_company_linkedin: companyLinkedIn,
        },
      },
      allWordpressPage: { edges },
    },
  } = props

  return (
    <section className="index">
      <Nav menuItemsSorted={handleMenuItems({ items: edges })} />
      <Hero
        carouselImages={carousel}
        carouselHeadline={carouselHeadline}
        carouselPageLink={carouselPageLink}
        carouselPageLinkDescription={carouselPageLinkDescription}
      />
      <Banners banners={banner} />
      <Footer companyName={companyName} companyTwitter={companyTwitter} companyLinkedIn={companyLinkedIn} menuItemsSorted={handleMenuItems({ items: edges })} />
    </section>
  )
}

export const pageQuery = graphql`
  query {
    wordpressPage(title: { eq: "Home" }) {
      acf {
        home_carousel_headline
        home_carousel_page_link
        home_carousel_page_link_description
        home_carousel {
          home_carousel_image {
            localFile {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
          }
        }
        home_banner {
          home_banner_headline
          home_banner_body
          home_banner_page_link
          home_banner_page_link_description
          home_banner_image {
            source_url
            localFile {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
          }
        }
      }
    }
    wordpressAcfOptions {
      options {
        sitewide_company_information_company_name
        sitewide_company_information_company_twitter
        sitewide_company_information_company_linkedin
      }
    }
    allWordpressPage(filter: { wordpress_parent: { eq: 0 } }) {
      edges {
        node {
          title
          menu_order
          slug
        }
      }
    }
  }
`
